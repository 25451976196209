import { onMounted, onUnmounted, ref } from "vue";
import { useResizeObserver } from "@vueuse/core";
import { createSharedComposable } from "@vueuse/core";

export const useRem = createSharedComposable(() => {
  const rem = ref(0);
  let documentElementComputedStyle: CSSStyleDeclaration;
  let prevRemSaved = "";
  let observer: ReturnType<typeof useResizeObserver>;

  onMounted(() => {
    documentElementComputedStyle = window.getComputedStyle(
      document.documentElement,
    );

    observer = useResizeObserver(document.documentElement, () => {
      const receivedRem =
        documentElementComputedStyle.getPropertyValue("--rem");
      if (receivedRem === "" || receivedRem === prevRemSaved) return;

      rem.value = parseFloat(receivedRem);
      prevRemSaved = receivedRem;
    });
  });

  onUnmounted(() => {
    observer?.stop();
  });

  return {
    rem,
  };
});
